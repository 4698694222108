/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/popper.js@1.14.7/dist/umd/popper.min.js
 * - /npm/bootstrap@4.3.1/dist/js/bootstrap.min.js
 * - /npm/soundcloud@3.3.2/sdk.min.js
 * - /npm/venobox@1.8.5/venobox/venobox.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
